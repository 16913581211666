.wi-wind-beaufort-0:before {
  content: $wind-beaufort-0;
}
.wi-wind-beaufort-1:before {
  content: $wind-beaufort-1;
}
.wi-wind-beaufort-2:before {
  content: $wind-beaufort-2;
}
.wi-wind-beaufort-3:before {
  content: $wind-beaufort-3;
}
.wi-wind-beaufort-4:before {
  content: $wind-beaufort-4;
}
.wi-wind-beaufort-5:before {
  content: $wind-beaufort-5;
}
.wi-wind-beaufort-6:before {
  content: $wind-beaufort-6;
}
.wi-wind-beaufort-7:before {
  content: $wind-beaufort-7;
}
.wi-wind-beaufort-8:before {
  content: $wind-beaufort-8;
}
.wi-wind-beaufort-9:before {
  content: $wind-beaufort-9;
}
.wi-wind-beaufort-10:before {
  content: $wind-beaufort-10;
}
.wi-wind-beaufort-11:before {
  content: $wind-beaufort-11;
}
.wi-wind-beaufort-12:before {
  content: $wind-beaufort-12;
}
