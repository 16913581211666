$moon-new: '\f095';
$moon-waxing-crescent-1: '\f096';
$moon-waxing-crescent-2: '\f097';
$moon-waxing-crescent-3: '\f098';
$moon-waxing-crescent-4: '\f099';
$moon-waxing-crescent-5: '\f09a';
$moon-waxing-crescent-6: '\f09b';
$moon-first-quarter: '\f09c';
$moon-waxing-gibbous-1: '\f09d';
$moon-waxing-gibbous-2: '\f09e';
$moon-waxing-gibbous-3: '\f09f';
$moon-waxing-gibbous-4: '\f0a0';
$moon-waxing-gibbous-5: '\f0a1';
$moon-waxing-gibbous-6: '\f0a2';
$moon-full: '\f0a3';
$moon-waning-gibbous-1: '\f0a4';
$moon-waning-gibbous-2: '\f0a5';
$moon-waning-gibbous-3: '\f0a6';
$moon-waning-gibbous-4: '\f0a7';
$moon-waning-gibbous-5: '\f0a8';
$moon-waning-gibbous-6: '\f0a9';
$moon-third-quarter: '\f0aa';
$moon-waning-crescent-1: '\f0ab';
$moon-waning-crescent-2: '\f0ac';
$moon-waning-crescent-3: '\f0ad';
$moon-waning-crescent-4: '\f0ae';
$moon-waning-crescent-5: '\f0af';
$moon-waning-crescent-6: '\f0b0';
$moon-alt-new: '\f0eb';
$moon-alt-waxing-crescent-1: '\f0d0';
$moon-alt-waxing-crescent-2: '\f0d1';
$moon-alt-waxing-crescent-3: '\f0d2';
$moon-alt-waxing-crescent-4: '\f0d3';
$moon-alt-waxing-crescent-5: '\f0d4';
$moon-alt-waxing-crescent-6: '\f0d5';
$moon-alt-first-quarter: '\f0d6';
$moon-alt-waxing-gibbous-1: '\f0d7';
$moon-alt-waxing-gibbous-2: '\f0d8';
$moon-alt-waxing-gibbous-3: '\f0d9';
$moon-alt-waxing-gibbous-4: '\f0da';
$moon-alt-waxing-gibbous-5: '\f0db';
$moon-alt-waxing-gibbous-6: '\f0dc';
$moon-alt-full: '\f0dd';
$moon-alt-waning-gibbous-1: '\f0de';
$moon-alt-waning-gibbous-2: '\f0df';
$moon-alt-waning-gibbous-3: '\f0e0';
$moon-alt-waning-gibbous-4: '\f0e1';
$moon-alt-waning-gibbous-5: '\f0e2';
$moon-alt-waning-gibbous-6: '\f0e3';
$moon-alt-third-quarter: '\f0e4';
$moon-alt-waning-crescent-1: '\f0e5';
$moon-alt-waning-crescent-2: '\f0e6';
$moon-alt-waning-crescent-3: '\f0e7';
$moon-alt-waning-crescent-4: '\f0e8';
$moon-alt-waning-crescent-5: '\f0e9';
$moon-alt-waning-crescent-6: '\f0ea';
