// Neutral
.#{$wi-css-prefix}-owm-200:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-owm-201:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-owm-202:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-owm-210:before {
  content: $lightning;
}
.#{$wi-css-prefix}-owm-211:before {
  content: $lightning;
}
.#{$wi-css-prefix}-owm-212:before {
  content: $lightning;
}
.#{$wi-css-prefix}-owm-221:before {
  content: $lightning;
}
.#{$wi-css-prefix}-owm-230:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-owm-231:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-owm-232:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-owm-300:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-owm-301:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-owm-302:before {
  content: $rain;
}
.#{$wi-css-prefix}-owm-310:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-owm-311:before {
  content: $rain;
}
.#{$wi-css-prefix}-owm-312:before {
  content: $rain;
}
.#{$wi-css-prefix}-owm-313:before {
  content: $showers;
}
.#{$wi-css-prefix}-owm-314:before {
  content: $rain;
}
.#{$wi-css-prefix}-owm-321:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-owm-500:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-owm-501:before {
  content: $rain;
}
.#{$wi-css-prefix}-owm-502:before {
  content: $rain;
}
.#{$wi-css-prefix}-owm-503:before {
  content: $rain;
}
.#{$wi-css-prefix}-owm-504:before {
  content: $rain;
}
.#{$wi-css-prefix}-owm-511:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-owm-520:before {
  content: $showers;
}
.#{$wi-css-prefix}-owm-521:before {
  content: $showers;
}
.#{$wi-css-prefix}-owm-522:before {
  content: $showers;
}
.#{$wi-css-prefix}-owm-531:before {
  content: $storm-showers;
}
.#{$wi-css-prefix}-owm-600:before {
  content: $snow;
}
.#{$wi-css-prefix}-owm-601:before {
  content: $snow;
}
.#{$wi-css-prefix}-owm-602:before {
  content: $sleet;
}
.#{$wi-css-prefix}-owm-611:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-owm-612:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-owm-615:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-owm-616:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-owm-620:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-owm-621:before {
  content: $snow;
}
.#{$wi-css-prefix}-owm-622:before {
  content: $snow;
}
.#{$wi-css-prefix}-owm-701:before {
  content: $showers;
}
.#{$wi-css-prefix}-owm-711:before {
  content: $smoke;
}
.#{$wi-css-prefix}-owm-721:before {
  content: $day-haze;
}
.#{$wi-css-prefix}-owm-731:before {
  content: $dust;
}
.#{$wi-css-prefix}-owm-741:before {
  content: $fog;
}
.#{$wi-css-prefix}-owm-761:before {
  content: $dust;
}
.#{$wi-css-prefix}-owm-762:before {
  content: $dust;
}
.#{$wi-css-prefix}-owm-771:before {
  content: $cloudy-gusts;
}
.#{$wi-css-prefix}-owm-781:before {
  content: $tornado;
}
.#{$wi-css-prefix}-owm-800:before {
  content: $day-sunny;
}
.#{$wi-css-prefix}-owm-801:before {
  content: $cloudy-gusts;
}
.#{$wi-css-prefix}-owm-802:before {
  content: $cloudy-gusts;
}
.#{$wi-css-prefix}-owm-803:before {
  content: $cloudy-windy;
}
.#{$wi-css-prefix}-owm-804:before {
  content: $cloudy;
}
.#{$wi-css-prefix}-owm-900:before {
  content: $tornado;
}
.#{$wi-css-prefix}-owm-901:before {
  content: $storm-showers;
}
.#{$wi-css-prefix}-owm-902:before {
  content: $hurricane;
}
.#{$wi-css-prefix}-owm-903:before {
  content: $snowflake-cold;
}
.#{$wi-css-prefix}-owm-904:before {
  content: $hot;
}
.#{$wi-css-prefix}-owm-905:before {
  content: $windy;
}
.#{$wi-css-prefix}-owm-906:before {
  content: $hail;
}
.#{$wi-css-prefix}-owm-957:before {
  content: $strong-wind;
}
//Day
.#{$wi-css-prefix}-owm-day-200:before {
  content: $day-thunderstorm;
}
.#{$wi-css-prefix}-owm-day-201:before {
  content: $day-thunderstorm;
}
.#{$wi-css-prefix}-owm-day-202:before {
  content: $day-thunderstorm;
}
.#{$wi-css-prefix}-owm-day-210:before {
  content: $day-lightning;
}
.#{$wi-css-prefix}-owm-day-211:before {
  content: $day-lightning;
}
.#{$wi-css-prefix}-owm-day-212:before {
  content: $day-lightning;
}
.#{$wi-css-prefix}-owm-day-221:before {
  content: $day-lightning;
}
.#{$wi-css-prefix}-owm-day-230:before {
  content: $day-thunderstorm;
}
.#{$wi-css-prefix}-owm-day-231:before {
  content: $day-thunderstorm;
}
.#{$wi-css-prefix}-owm-day-232:before {
  content: $day-thunderstorm;
}
.#{$wi-css-prefix}-owm-day-300:before {
  content: $day-sprinkle;
}
.#{$wi-css-prefix}-owm-day-301:before {
  content: $day-sprinkle;
}
.#{$wi-css-prefix}-owm-day-302:before {
  content: $day-rain;
}
.#{$wi-css-prefix}-owm-day-310:before {
  content: $day-rain;
}
.#{$wi-css-prefix}-owm-day-311:before {
  content: $day-rain;
}
.#{$wi-css-prefix}-owm-day-312:before {
  content: $day-rain;
}
.#{$wi-css-prefix}-owm-day-313:before {
  content: $day-rain;
}
.#{$wi-css-prefix}-owm-day-314:before {
  content: $day-rain;
}
.#{$wi-css-prefix}-owm-day-321:before {
  content: $day-sprinkle;
}
.#{$wi-css-prefix}-owm-day-500:before {
  content: $day-sprinkle;
}
.#{$wi-css-prefix}-owm-day-501:before {
  content: $day-rain;
}
.#{$wi-css-prefix}-owm-day-502:before {
  content: $day-rain;
}
.#{$wi-css-prefix}-owm-day-503:before {
  content: $day-rain;
}
.#{$wi-css-prefix}-owm-day-504:before {
  content: $day-rain;
}
.#{$wi-css-prefix}-owm-day-511:before {
  content: $day-rain-mix;
}
.#{$wi-css-prefix}-owm-day-520:before {
  content: $day-showers;
}
.#{$wi-css-prefix}-owm-day-521:before {
  content: $day-showers;
}
.#{$wi-css-prefix}-owm-day-522:before {
  content: $day-showers;
}
.#{$wi-css-prefix}-owm-day-531:before {
  content: $day-storm-showers;
}
.#{$wi-css-prefix}-owm-day-600:before {
  content: $day-snow;
}
.#{$wi-css-prefix}-owm-day-601:before {
  content: $day-sleet;
}
.#{$wi-css-prefix}-owm-day-602:before {
  content: $day-snow;
}
.#{$wi-css-prefix}-owm-day-611:before {
  content: $day-rain-mix;
}
.#{$wi-css-prefix}-owm-day-612:before {
  content: $day-rain-mix;
}
.#{$wi-css-prefix}-owm-day-615:before {
  content: $day-rain-mix;
}
.#{$wi-css-prefix}-owm-day-616:before {
  content: $day-rain-mix;
}
.#{$wi-css-prefix}-owm-day-620:before {
  content: $day-rain-mix;
}
.#{$wi-css-prefix}-owm-day-621:before {
  content: $day-snow;
}
.#{$wi-css-prefix}-owm-day-622:before {
  content: $day-snow;
}
.#{$wi-css-prefix}-owm-day-701:before {
  content: $day-showers;
}
.#{$wi-css-prefix}-owm-day-711:before {
  content: $smoke;
}
.#{$wi-css-prefix}-owm-day-721:before {
  content: $day-haze;
}
.#{$wi-css-prefix}-owm-day-731:before {
  content: $dust;
}
.#{$wi-css-prefix}-owm-day-741:before {
  content: $day-fog;
}
.#{$wi-css-prefix}-owm-day-761:before {
  content: $dust;
}
.#{$wi-css-prefix}-owm-day-762:before {
  content: $dust;
}
.#{$wi-css-prefix}-owm-day-781:before {
  content: $tornado;
}
.#{$wi-css-prefix}-owm-day-800:before {
  content: $day-sunny;
}
.#{$wi-css-prefix}-owm-day-801:before {
  content: $day-cloudy-gusts;
}
.#{$wi-css-prefix}-owm-day-802:before {
  content: $day-cloudy-gusts;
}
.#{$wi-css-prefix}-owm-day-803:before {
  content: $day-cloudy-gusts;
}
.#{$wi-css-prefix}-owm-day-804:before {
  content: $day-sunny-overcast;
}
.#{$wi-css-prefix}-owm-day-900:before {
  content: $tornado;
}
.#{$wi-css-prefix}-owm-day-902:before {
  content: $hurricane;
}
.#{$wi-css-prefix}-owm-day-903:before {
  content: $snowflake-cold;
}
.#{$wi-css-prefix}-owm-day-904:before {
  content: $hot;
}
.#{$wi-css-prefix}-owm-day-906:before {
  content: $day-hail;
}
.#{$wi-css-prefix}-owm-day-957:before {
  content: $strong-wind;
}
//Night
.#{$wi-css-prefix}-owm-night-200:before {
  content: $night-alt-thunderstorm;
}
.#{$wi-css-prefix}-owm-night-201:before {
  content: $night-alt-thunderstorm;
}
.#{$wi-css-prefix}-owm-night-202:before {
  content: $night-alt-thunderstorm;
}
.#{$wi-css-prefix}-owm-night-210:before {
  content: $night-alt-lightning;
}
.#{$wi-css-prefix}-owm-night-211:before {
  content: $night-alt-lightning;
}
.#{$wi-css-prefix}-owm-night-212:before {
  content: $night-alt-lightning;
}
.#{$wi-css-prefix}-owm-night-221:before {
  content: $night-alt-lightning;
}
.#{$wi-css-prefix}-owm-night-230:before {
  content: $night-alt-thunderstorm;
}
.#{$wi-css-prefix}-owm-night-231:before {
  content: $night-alt-thunderstorm;
}
.#{$wi-css-prefix}-owm-night-232:before {
  content: $night-alt-thunderstorm;
}
.#{$wi-css-prefix}-owm-night-300:before {
  content: $night-alt-sprinkle;
}
.#{$wi-css-prefix}-owm-night-301:before {
  content: $night-alt-sprinkle;
}
.#{$wi-css-prefix}-owm-night-302:before {
  content: $night-alt-rain;
}
.#{$wi-css-prefix}-owm-night-310:before {
  content: $night-alt-rain;
}
.#{$wi-css-prefix}-owm-night-311:before {
  content: $night-alt-rain;
}
.#{$wi-css-prefix}-owm-night-312:before {
  content: $night-alt-rain;
}
.#{$wi-css-prefix}-owm-night-313:before {
  content: $night-alt-rain;
}
.#{$wi-css-prefix}-owm-night-314:before {
  content: $night-alt-rain;
}
.#{$wi-css-prefix}-owm-night-321:before {
  content: $night-alt-sprinkle;
}
.#{$wi-css-prefix}-owm-night-500:before {
  content: $night-alt-sprinkle;
}
.#{$wi-css-prefix}-owm-night-501:before {
  content: $night-alt-rain;
}
.#{$wi-css-prefix}-owm-night-502:before {
  content: $night-alt-rain;
}
.#{$wi-css-prefix}-owm-night-503:before {
  content: $night-alt-rain;
}
.#{$wi-css-prefix}-owm-night-504:before {
  content: $night-alt-rain;
}
.#{$wi-css-prefix}-owm-night-511:before {
  content: $night-alt-rain-mix;
}
.#{$wi-css-prefix}-owm-night-520:before {
  content: $night-alt-showers;
}
.#{$wi-css-prefix}-owm-night-521:before {
  content: $night-alt-showers;
}
.#{$wi-css-prefix}-owm-night-522:before {
  content: $night-alt-showers;
}
.#{$wi-css-prefix}-owm-night-531:before {
  content: $night-alt-storm-showers;
}
.#{$wi-css-prefix}-owm-night-600:before {
  content: $night-alt-snow;
}
.#{$wi-css-prefix}-owm-night-601:before {
  content: $night-alt-sleet;
}
.#{$wi-css-prefix}-owm-night-602:before {
  content: $night-alt-snow;
}
.#{$wi-css-prefix}-owm-night-611:before {
  content: $night-alt-rain-mix;
}
.#{$wi-css-prefix}-owm-night-612:before {
  content: $night-alt-rain-mix;
}
.#{$wi-css-prefix}-owm-night-615:before {
  content: $night-alt-rain-mix;
}
.#{$wi-css-prefix}-owm-night-616:before {
  content: $night-alt-rain-mix;
}
.#{$wi-css-prefix}-owm-night-620:before {
  content: $night-alt-rain-mix;
}
.#{$wi-css-prefix}-owm-night-621:before {
  content: $night-alt-snow;
}
.#{$wi-css-prefix}-owm-night-622:before {
  content: $night-alt-snow;
}
.#{$wi-css-prefix}-owm-night-701:before {
  content: $night-alt-showers;
}
.#{$wi-css-prefix}-owm-night-711:before {
  content: $smoke;
}
.#{$wi-css-prefix}-owm-night-721:before {
  content: $day-haze;
}
.#{$wi-css-prefix}-owm-night-731:before {
  content: $dust;
}
.#{$wi-css-prefix}-owm-night-741:before {
  content: $night-fog;
}
.#{$wi-css-prefix}-owm-night-761:before {
  content: $dust;
}
.#{$wi-css-prefix}-owm-night-762:before {
  content: $dust;
}
.#{$wi-css-prefix}-owm-night-781:before {
  content: $tornado;
}
.#{$wi-css-prefix}-owm-night-800:before {
  content: $night-clear;
}
.#{$wi-css-prefix}-owm-night-801:before {
  content: $night-alt-cloudy-gusts;
}
.#{$wi-css-prefix}-owm-night-802:before {
  content: $night-alt-cloudy-gusts;
}
.#{$wi-css-prefix}-owm-night-803:before {
  content: $night-alt-cloudy-gusts;
}
.#{$wi-css-prefix}-owm-night-804:before {
  content: $night-alt-cloudy;
}
.#{$wi-css-prefix}-owm-night-900:before {
  content: $tornado;
}
.#{$wi-css-prefix}-owm-night-902:before {
  content: $hurricane;
}
.#{$wi-css-prefix}-owm-night-903:before {
  content: $snowflake-cold;
}
.#{$wi-css-prefix}-owm-night-904:before {
  content: $hot;
}
.#{$wi-css-prefix}-owm-night-906:before {
  content: $night-alt-hail;
}
.#{$wi-css-prefix}-owm-night-957:before {
  content: $strong-wind;
}
