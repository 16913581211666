.icon {
  display: inline-block;

  &-check {
    background-color: #6fcf97;
    border-radius: 50%;
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    &:before {
      color: #fff;
      font-size: 32px;
    }
  }

  &-info {
    background-color: $blue;
    border-radius: 50%;
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    &:before {
      content: '';
      background: url('/assets/img/svg/icons/ic-info.svg') no-repeat center
        center;
      display: block;
      width: 32px;
      height: 32px;
    }
  }

  &-empty {
    border: 3px solid#E0E0E0;
    border-radius: 50%;
    display: block;
    position: relative;
    width: 32px;
    height: 32px;

    &-white {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      display: block;
      position: relative;
      width: 32px;
      height: 32px;
    }
  }
  &-chevron-right {
    background: url('/assets/img/svg/icons/ic-chevron-right.svg') no-repeat
      center center;
    display: inline-block;
    width: 12px;
    height: 12px;
  }
}
