// Theme Colors
$theme-colors: (
  primary: #2f80ed,
  secondary: #828282,
  tertiary: #fd586c,
  success: #0cc27e,
  info: #1cbcd8,
  warning: #ff8d60,
  danger: #ff586b,
  light: #f8f9fa,
  dark: #343a40
);

// Spacing
$spacer: 1.5rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25
  ),
  2: (
    $spacer * 0.75
  ),
  3: $spacer,
  4: (
    $spacer * 1.5
  ),
  5: (
    $spacer * 3
  )
) !default;
// Body
$body-bg: #fff;
// Fonts
$font-family-sans-serif: 'Barlow', 'Montserrat', 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif;
$font-family-content: 'Barlow', 'Rubik', 'Segoe UI', Roboto, 'Helvetica Neue',
  Arial, sans-serif;
$font-size-root: 16px;

// Colors
$blue-background: #f9fafa;
$blue-general: #567af9;
$blue-disabled: #acccf8;
$blue-bars: #407df2;
$facebook: #4267b2;
$twitter: #1da1f2;
$black: #000000;
$white: #ffffff;
$gray-paragraph: #4f4f4f;
$gray-titles: #828282;
$gray-background: #d6d6d6;
$error: #eb5757;
$dark-gray: #333333;

//Margins
$base-margin: 8px;
$margin-x2: #{$base-margin * 2};
$margin-x3: #{$base-margin * 3};
$margin-x4: #{$base-margin * 4};
$margin-x5: #{$base-margin * 5};
$margin-x6: #{$base-margin * 6};
$margin-x7: #{$base-margin * 7};
$margin-x8: #{$base-margin * 8};
$margin-x9: #{$base-margin * 9};
$margin-x10: #{$base-margin * 10};
$margin-x11: #{$base-margin * 11};
$margin-x12: #{$base-margin * 12};
$margin-x13: #{$base-margin * 13};

//Borders
$general-border-radius: 5px;
$general-border: 1px solid rgba(0, 0, 0, 0.1);
