.#{$wi-css-prefix}-wmo4680-0:before,
.#{$wi-css-prefix}-wmo4680-00:before {
  content: $thermometer;
}
.#{$wi-css-prefix}-wmo4680-1:before,
.#{$wi-css-prefix}-wmo4680-01:before {
  content: $cloudy;
}
.#{$wi-css-prefix}-wmo4680-2:before,
.#{$wi-css-prefix}-wmo4680-02:before {
  content: $thermometer;
}
.#{$wi-css-prefix}-wmo4680-3:before,
.#{$wi-css-prefix}-wmo4680-03:before {
  content: $cloudy;
}
.#{$wi-css-prefix}-wmo4680-4:before,
.#{$wi-css-prefix}-wmo4680-04:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-5:before,
.#{$wi-css-prefix}-wmo4680-05:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-10:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-11:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-12:before {
  content: $lightning;
}
.#{$wi-css-prefix}-wmo4680-18:before {
  content: $strong-wind;
}
.#{$wi-css-prefix}-wmo4680-20:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-21:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-wmo4680-22:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-wmo4680-23:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-24:before {
  content: $snow;
}
.#{$wi-css-prefix}-wmo4680-25:before {
  content: $hail;
}
.#{$wi-css-prefix}-wmo4680-26:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-wmo4680-27:before {
  content: $dust;
}
.#{$wi-css-prefix}-wmo4680-28:before {
  content: $dust;
}
.#{$wi-css-prefix}-wmo4680-29:before {
  content: $dust;
}
.#{$wi-css-prefix}-wmo4680-30:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-31:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-32:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-33:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-34:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-35:before {
  content: $fog;
}
.#{$wi-css-prefix}-wmo4680-40:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-wmo4680-41:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-wmo4680-42:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-43:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-wmo4680-44:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-45:before {
  content: $hail;
}
.#{$wi-css-prefix}-wmo4680-46:before {
  content: $hail;
}
.#{$wi-css-prefix}-wmo4680-47:before {
  content: $snow;
}
.#{$wi-css-prefix}-wmo4680-48:before {
  content: $snow;
}
.#{$wi-css-prefix}-wmo4680-50:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-wmo4680-51:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-wmo4680-52:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-53:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-54:before {
  content: $snowflake-cold;
}
.#{$wi-css-prefix}-wmo4680-55:before {
  content: $snowflake-cold;
}
.#{$wi-css-prefix}-wmo4680-56:before {
  content: $snowflake-cold;
}
.#{$wi-css-prefix}-wmo4680-57:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-wmo4680-58:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-60:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-wmo4680-61:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-wmo4680-62:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-63:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-64:before {
  content: $hail;
}
.#{$wi-css-prefix}-wmo4680-65:before {
  content: $hail;
}
.#{$wi-css-prefix}-wmo4680-66:before {
  content: $hail;
}
.#{$wi-css-prefix}-wmo4680-67:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-wmo4680-68:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-wmo4680-70:before {
  content: $snow;
}
.#{$wi-css-prefix}-wmo4680-71:before {
  content: $snow;
}
.#{$wi-css-prefix}-wmo4680-72:before {
  content: $snow;
}
.#{$wi-css-prefix}-wmo4680-73:before {
  content: $snow;
}
.#{$wi-css-prefix}-wmo4680-74:before {
  content: $snowflake-cold;
}
.#{$wi-css-prefix}-wmo4680-75:before {
  content: $snowflake-cold;
}
.#{$wi-css-prefix}-wmo4680-76:before {
  content: $snowflake-cold;
}
.#{$wi-css-prefix}-wmo4680-77:before {
  content: $snow;
}
.#{$wi-css-prefix}-wmo4680-78:before {
  content: $snowflake-cold;
}
.#{$wi-css-prefix}-wmo4680-80:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-81:before {
  content: $sprinkle;
}
.#{$wi-css-prefix}-wmo4680-82:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-83:before {
  content: $rain;
}
.#{$wi-css-prefix}-wmo4680-84:before {
  content: $storm-showers;
}
.#{$wi-css-prefix}-wmo4680-85:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-wmo4680-86:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-wmo4680-87:before {
  content: $rain-mix;
}
.#{$wi-css-prefix}-wmo4680-89:before {
  content: $hail;
}
.#{$wi-css-prefix}-wmo4680-90:before {
  content: $lightning;
}
.#{$wi-css-prefix}-wmo4680-91:before {
  content: $storm-showers;
}
.#{$wi-css-prefix}-wmo4680-92:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-wmo4680-93:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-wmo4680-94:before {
  content: $lightning;
}
.#{$wi-css-prefix}-wmo4680-95:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-wmo4680-96:before {
  content: $thunderstorm;
}
.#{$wi-css-prefix}-wmo4680-99:before {
  content: $tornado;
}
