.wi-moon-0:before {
  content: $moon-new;
}
.wi-moon-1:before {
  content: $moon-waxing-crescent-1;
}
.wi-moon-2:before {
  content: $moon-waxing-crescent-2;
}
.wi-moon-3:before {
  content: $moon-waxing-crescent-3;
}
.wi-moon-4:before {
  content: $moon-waxing-crescent-4;
}
.wi-moon-5:before {
  content: $moon-waxing-crescent-5;
}
.wi-moon-6:before {
  content: $moon-waxing-crescent-6;
}
.wi-moon-7:before {
  content: $moon-first-quarter;
}
.wi-moon-8:before {
  content: $moon-waxing-gibbous-1;
}
.wi-moon-9:before {
  content: $moon-waxing-gibbous-2;
}
.wi-moon-10:before {
  content: $moon-waxing-gibbous-3;
}
.wi-moon-11:before {
  content: $moon-waxing-gibbous-4;
}
.wi-moon-12:before {
  content: $moon-waxing-gibbous-5;
}
.wi-moon-13:before {
  content: $moon-waxing-gibbous-6;
}
.wi-moon-14:before {
  content: $moon-full;
}
.wi-moon-15:before {
  content: $moon-waning-gibbous-1;
}
.wi-moon-16:before {
  content: $moon-waning-gibbous-2;
}
.wi-moon-17:before {
  content: $moon-waning-gibbous-3;
}
.wi-moon-18:before {
  content: $moon-waning-gibbous-4;
}
.wi-moon-19:before {
  content: $moon-waning-gibbous-5;
}
.wi-moon-20:before {
  content: $moon-waning-gibbous-6;
}
.wi-moon-21:before {
  content: $moon-third-quarter;
}
.wi-moon-22:before {
  content: $moon-waning-crescent-1;
}
.wi-moon-23:before {
  content: $moon-waning-crescent-2;
}
.wi-moon-24:before {
  content: $moon-waning-crescent-3;
}
.wi-moon-25:before {
  content: $moon-waning-crescent-4;
}
.wi-moon-26:before {
  content: $moon-waning-crescent-5;
}
.wi-moon-27:before {
  content: $moon-waning-crescent-6;
}
