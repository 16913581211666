// Width and height

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}

.h-72 {
  height: 72px;
}
.h-144 {
  height: 144px;
}

// Margin and Padding

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

a {
  cursor: pointer;
}

// Icons
.fonticon-wrap {
  width: 2%;
  float: left;
  margin: 0.25rem;
  i.fa {
    font-size: 1.35rem !important;
  }
}

.round {
  border-radius: 1.5rem;
}
.square {
  border-radius: 0;
}
img {
  &.img-border {
    border: 5px solid #fff;
  }
}
ul {
  &.no-list-style {
    list-style: none;
  }
}
[class*='gradient-'] {
  background-repeat: repeat !important;
}
// Shadow classes
.shadow-z-1 {
  @include shadow-z-1();
}
.shadow-z-1-hover {
  @include shadow-z-1-hover();
}
.shadow-z-2 {
  @include shadow-z-2();
}
.shadow-z-3 {
  @include shadow-z-3();
}
.shadow-z-4 {
  @include shadow-z-4();
}
.shadow-z-5 {
  @include shadow-z-5();
}
.big-shadow {
  @include big-shadow();
}
.shadow-big {
  @include shadow-big();
}
.shadow-big-navbar {
  @include shadow-big-navbar();
}
.sidebar-shadow {
  @include sidebar-shadow();
}
.card-shadow {
  @include card-shadow();
}
