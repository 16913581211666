@mixin forCustomMin($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}

@mixin forCustomMax($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}

@mixin gradient-directional(
  $start-color: $gray-700,
  $end-color: $gray-800,
  $deg: 45deg
) {
  background-image: linear-gradient($deg, $start-color, $end-color);
  background-repeat: repeat-x;
}
