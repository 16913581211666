my-app .main-panel .main-content .card {
  animation-duration: 750ms;
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

#map {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 70px;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.space-70 {
  height: 70px;
  display: block;
}

@media (max-width: 991px) {
  .sidebar .nav > li.active-pro {
    position: relative;
    width: 100%;
    bottom: 10px;
  }
  .sidebar .nav > li.active-pro > a {
    margin-top: 20px;
  }
}

.tim-row {
  margin-bottom: 20px;
}

.tim-typo {
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
}
.tim-typo .tim-note {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}
.tim-row {
  padding-top: 50px;
}
.tim-row h3 {
  margin-top: 0;
}

/* 
.modal {
  position: relative;
  animation: animatebottom 0.4s;
  background-color: red;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
} */
