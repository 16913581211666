// Variable defiend for this demo app
$w-sidebar: 250px;

// Template Starter Kit Scss file
@media (min-width: 512px) {
  .wrapper {
    position: relative;
    top: 0;
    height: calc(100% - 96px);

    .content {
      &-header {
        letter-spacing: 0.5px;
        font-size: 1.6rem;
        font-weight: 500;
        margin-top: 1.5rem;
        margin-bottom: 0.25rem;
      }

      &-sub-header {
        color: darken($body-bg, 60%);
      }
    }

    /** Collapsed App Sidebar Styles: unused >> **/
    &.nav-collapsed {
      .app-sidebar {
        // Default width
        width: 60px;

        &:hover {
          width: $w-sidebar;

          .nav li.has-sub > a:after {
            display: inherit;
          }

          .menu-title,
          .badge {
            display: inline-block;
          }

          .logo .text {
            display: inline-block;
          }

          .nav-toggle {
            display: block !important;
          }
        }

        .menu-title {
          display: none;
        }

        .badge {
          display: none;
        }

        .logo .text {
          display: none;
        }

        .nav-toggle {
          display: none !important;
        }

        .nav-close {
          display: none !important;
        }
      }

      .main-panel {
        .main-content {
          padding-left: 60px;
        }

        .navbar {
          .navbar-header {
            padding-left: 50px;
          }
        }
      }

      footer {
        padding-left: 60px;
      }
    }

    &.menu-collapsed {
      .app-sidebar {
        .navigation li.has-sub > a:after {
          display: none;
        }
      }
    }

    /** << Collapsed App Sidebar Styles: unused **/
  }
}
/** Bootstrap Styles used by this template >> **/

.bootstrap-navbar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
}

/** << Bootstrap Styles used by this template **/

/**
App Sidebar Styles from template
Unused classes:
.off-canvas-sidebar
.hide-sidebar
.logo-img
.logo-tim
.logo-text
.open
.logo > p
**/

.app-sidebar,
.off-canvas-sidebar {
  font-family: $font-family-base;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: $w-sidebar;
  height: 100vh;
  transform: translate3d(0, 0, 0);
  transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
  white-space: nowrap;
  visibility: visible; // z-index: 10005 !important;
  z-index: 4;

  &.hide-sidebar {
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); // z-index: 10005 !important;
  }

  .sidebar-content {
    height: calc((var(--vh, 1vh) * 100) - 90px);
    overflow: auto;
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
  }

  .logo-img {
    max-height: 30px;
    margin-right: 15px;
    width: 30px;
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .navigation {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    li {
      display: block;
      position: relative; // Sub Level Menu Element

      &.has-sub {
        > a:after {
          content: '\e929';
          font-family: 'feather';
          font-size: 1rem;
          display: inline-block;
          position: absolute;
          right: 15px;
          top: 10px;
          transform: rotate(0);
          transition: transform 0.2s ease-in-out;
        }

        > a {
          margin-bottom: 0;
        }
      }

      &.open {
        > a:after {
          transform: rotate(90deg);
        }

        > a {
          background: rgba(200, 200, 200, 0.2);
        }
      }

      // Link Normal State
      > a {
        background-color: transparent;
        color: $gray-darken-2-color !important;
        display: block;
        line-height: $font-size-base * 2;
        margin: 0;
        overflow: hidden;
        padding: 10px 30px 10px 14px;
        transition: all 300ms linear;

        &:before {
          content: '';
          background: rgba(255, 255, 255, 0.45);
          border-right: solid 5px $blue-accent-5-color;
          display: block;
          opacity: 0;
          position: absolute;
          left: -20px;
          top: 0;
          width: 100%;
          height: 100%;
          transition: all 0.15s ease-in;
          z-index: 1;
        }
      }

      &:not(.open) {
        > ul {
          display: none;
        }
      }

      // Hover state
      &:hover > a {
        background: rgba(200, 200, 200, 0.2);
        color: inherit;
      }

      // Active state
      &.active > a {
        &:before {
          left: 0;
          opacity: 1;
        }

        .menu-title {
          color: $black;
        }

        i {
          color: $black;
        }
      }

      ul {
        font-size: 1rem;
        margin: 0;
        overflow: hidden;
        padding: 0;

        li {
          a {
            display: block;
            padding: 8px 0;
          }

          ul {
            li {
              a {
                padding: 8px 0;
              }
            }
          }
        }
      }
    }

    .menu-title {
      font-size: 1rem;
      font-weight: 600;
      height: auto;
      letter-spacing: 0.6px;
      margin: 0;
      opacity: 1;
      transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .badge.badge-pill {
      font-weight: 300;
      position: absolute;
      right: 28px;
      padding: 4px 8px;
      transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    // Menue Icons
    i {
      color: $gray-darken-2-color !important;
      float: left;
      font-size: 18px;
      line-height: 2rem;
      margin-right: 10px;
      width: 30px;
      text-align: center;
    }
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;

    &:after {
      content: '';
      background: #ffffff;
      display: block;
      opacity: 0.93;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &:after,
  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    opacity: 0.33;
  }

  &:after {
    z-index: 3;
    opacity: 1;
  }

  &[data-image]:after,
  &.has-image:after {
    opacity: 0.77;
  }

  // Background colors
  &[data-background-color='white'] {
    @include set-background-color($white);
  }

  &[data-background-color='black'] {
    @include set-background-color($black);
  }

  &[data-background-color='primary'] {
    @include set-background-color(theme-color('primary'));
  }

  &[data-background-color='info'] {
    @include set-background-color(theme-color('info'));
  }

  &[data-background-color='success'] {
    @include set-background-color(theme-color('success'));
  }

  &[data-background-color='warning'] {
    @include set-background-color(theme-color('warning'));
  }

  &[data-background-color='danger'] {
    @include set-background-color(theme-color('danger'));
  }

  &[data-background-color='lavender'] {
    @include set-background-color(map-get($purple, lighten-6));
  }

  // Gradient Background Colors
  &[data-background-color='pomegranate'] {
    @include set-gradient-background-color(#9b3cb7, #ff396f);
  }

  &[data-background-color='king-yna'] {
    @include set-gradient-background-color(#1a2a6c, #b21f1f);
  }

  &[data-background-color='ibiza-sunset'] {
    @include set-gradient-background-color(#ee0979, #ff6a00);
  }

  &[data-background-color='flickr'] {
    @include set-gradient-background-color(#33001b, #ff0084);
  }

  &[data-background-color='purple-bliss'] {
    @include set-gradient-background-color(#360033, #0b8793);
  }

  &[data-background-color='man-of-steel'] {
    @include set-gradient-background-color(#780206, #061161);
  }

  &[data-background-color='purple-love'] {
    @include set-gradient-background-color(#cc2b5e, #753a88);
  }

  // Menu anchor colors
  &[data-background-color='white'] {
    @include set-anchor-color($black);
  }

  &[data-background-color='black'] {
    @include set-anchor-color($white);
  }

  &[data-background-color='red'] {
    @include set-anchor-color($white);
  }

  // Logo Text Color
  &[data-background-color='white'] {
    @include set-logo-text-color($black);
  }

  &[data-color='blue'] {
    @include set-background-color-button(theme-color('info'));
  }

  &[data-color='green'] {
    @include set-background-color-button(theme-color('success'));
  }

  &[data-color='orange'] {
    @include set-background-color-button(theme-color('warning'));
  }

  &[data-color='red'] {
    @include set-background-color-button(theme-color('danger'));
  }

  &[data-color='purple'] {
    @include set-background-color-button(theme-color('primary'));
  }
}

/**
Unused Styles
**/

.off-canvas-sidebar {
  .navigation {
    > li > a,
    > li > a:hover {
      color: $white;
    }

    > li > a:focus {
      background: rgba(200, 200, 200, 0.2);
    }
  }
}

// The main content area under the .wrapper in default-layout component
.main-panel {
  position: relative; // z-index: 2;
  float: right;
  overflow: auto;
  min-height: 100%;
  @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

  .main-content {
    position: relative;
    min-height: calc(100% - 123px);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  div {
    .footer {
      border-top: 1px solid #e7e7e7;
    }
  }

  > .navbar {
    margin-bottom: 0;
  }
}

.bootstrap-navbar .navigation {
  margin-top: 20px;
  float: none;
}

.main-panel {
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.sidebar-sm {
  .app-sidebar {
    width: 220px;
  }
}

.sidebar-lg {
  .app-sidebar {
    width: 280px;
  }
}
