$blue: #2f80ed;

.form-group {
  .checkbox label,
  .radio label {
    color: rgba(#000000, 0.87);
  }
}

// Buttons
.btn-group,
.input-group {
  span {
    &:first-child:not(:last-child) {
      .btn.dropdown-toggle {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child:not(:first-child) {
      .btn.dropdown-toggle {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &:not(:last-child):not(:first-child) {
      .btn.dropdown-toggle {
        border-radius: 0;
      }
    }
  }
}

// List Group
.list-group-item {
  &.active,
  &.active:focus,
  &.active:hover {
    background-color: theme-color('primary');
    border-color: theme-color('primary');
  }
}

// Labels
.label {
  padding-bottom: 0.1em !important;
  letter-spacing: 0.5px;

  &.label-default {
    background-color: #777 !important;
  }
}

// Media Object
.media-list {
  &.media-bordered {
    .media {
      border-top: 1px solid #e4e7ed;
      padding: 1rem 0;

      &:first-child {
        border-top: 0;
      }
    }
  }
}

.media {
  img,
  iframe {
    margin-right: 1rem !important;
  }

  .media-body + a {
    img {
      margin-right: 0 !important;
      margin-left: 1rem;
    }
  }

  .media-body.text-right + a {
    img {
      margin-left: 0;
    }
  }

  .media-body,
  .media-left,
  .media-right {
    padding-top: 0;
  }

  .round-media {
    border-radius: 50%;
  }
}

// Dropdown
.dropdown-menu {
  display: none;
  opacity: inherit;

  .active {
    a {
      background-color: theme-color('primary');

      &:focus,
      &:hover {
        background-color: theme-color('primary');
      }
    }
  }

  &.show {
    transform: none;
    opacity: 1;
  }
}

.form-control {
  &.open {
    ~ .dropdown-menu {
      transform: none;
      opacity: 1;
    }
  }
}

// Tabs/Navs/Pills
.tab-content {
  padding: 1rem;
}

.nav-tabs {
  .nav-link {
    height: 100%;

    &.active {
      background-color: transparent;
      border-bottom-color: white;
    }
  }

  .nav-item {
    &.show {
      .nav-link {
        background-color: transparent;
        border-bottom-color: white;
      }
    }
  }
}

// Accordion
ngb-accordion {
  [role='tabpanel'] {
    padding: 0 2.5rem !important;
  }

  .card {
    border-bottom: 2px solid #f5f7fa;
    margin: 0;
  }
}

.swal2-modal {
  .swal2-confirm {
    background-color: #2f80ed !important;

    &:focus {
      box-shadow: none;
    }
  }

  .swal2-cancel {
    background-color: theme-color('danger');
  }
}

.swal2-icon {
  &-text {
    font-size: 34px;
  }

  &.swal2-success {
    border-color: $blue !important;

    .swal2-success-ring {
      // Ring
      border: 0.25em solid $blue !important;
    }

    [class^='swal2-success-line'] {
      background-color: $blue !important;
    }
  }
}

.general-modal {
  .swal2-icon.swal2-warning {
    border-color: #eb5757 !important;
    color: #eb5757 !important;
  }

  .sent-amount {
    background: $blue;
    border-radius: 2px;
    color: #ffffff;
    display: inline-block;
    font-weight: 500;
    padding: 5px 8px;
    margin: 0 3px;
  }

  .warning-msg {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin: 16px;
    padding: 16px;

    p {
      color: #4f4f4f;
      font-size: 0.75em;
      font-weight: 600;
      text-align: left;
    }

    ul {
      text-align: left;
      padding-left: 32px;
    }

    li {
      font-size: 0.75em;
      font-weight: normal;
    }

    .ic-warning {
      background: url('/assets/img/svg/icons/ic-alert-triangle-red.svg')
        no-repeat center center;
      bottom: -8px;
      display: inline-block;
      height: 25px;
      margin-right: 5px;
      position: relative;
      width: 29px;
    }
  }

  .swal2-actions {
    width: 100%;
    @apply m-auto my-1;
  }

  .btn-confirm {
    @apply px-8 text-white rounded-full bg-blue-dark h-10 mb-4;

    &:active {
      background-color: #4866d1;
      background-size: 100%;
      transition: background 0s;
      outline: none;
    }
  }
}

.editor-link {
  text-decoration: underline;
  font-weight: 500;
  color: inherit;
  font-style: normal;
}
