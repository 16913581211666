.wi-direction-up:before {
  content: $direction-up;
}
.wi-direction-up-right:before {
  content: $direction-up-right;
}
.wi-direction-right:before {
  content: $direction-right;
}
.wi-direction-down-right:before {
  content: $direction-down-right;
}
.wi-direction-down:before {
  content: $direction-down;
}
.wi-direction-down-left:before {
  content: $direction-down-left;
}
.wi-direction-left:before {
  content: $direction-left;
}
.wi-direction-up-left:before {
  content: $direction-up-left;
}
