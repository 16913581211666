.wi-night-clear:before {
  content: $night-clear;
}
.wi-night-alt-cloudy:before {
  content: $night-alt-cloudy;
}
.wi-night-alt-cloudy-gusts:before {
  content: $night-alt-cloudy-gusts;
}
.wi-night-alt-cloudy-windy:before {
  content: $night-alt-cloudy-windy;
}
.wi-night-alt-hail:before {
  content: $night-alt-hail;
}
.wi-night-alt-lightning:before {
  content: $night-alt-lightning;
}
.wi-night-alt-rain:before {
  content: $night-alt-rain;
}
.wi-night-alt-rain-mix:before {
  content: $night-alt-rain-mix;
}
.wi-night-alt-rain-wind:before {
  content: $night-alt-rain-wind;
}
.wi-night-alt-showers:before {
  content: $night-alt-showers;
}
.wi-night-alt-sleet:before {
  content: $night-alt-sleet;
}
.wi-night-alt-sleet-storm:before {
  content: $night-alt-sleet-storm;
}
.wi-night-alt-snow:before {
  content: $night-alt-snow;
}
.wi-night-alt-snow-thunderstorm:before {
  content: $night-alt-snow-thunderstorm;
}
.wi-night-alt-snow-wind:before {
  content: $night-alt-snow-wind;
}
.wi-night-alt-sprinkle:before {
  content: $night-alt-sprinkle;
}
.wi-night-alt-storm-showers:before {
  content: $night-alt-storm-showers;
}
.wi-night-alt-thunderstorm:before {
  content: $night-alt-thunderstorm;
}
.wi-night-cloudy:before {
  content: $night-cloudy;
}
.wi-night-cloudy-gusts:before {
  content: $night-cloudy-gusts;
}
.wi-night-cloudy-windy:before {
  content: $night-cloudy-windy;
}
.wi-night-fog:before {
  content: $night-fog;
}
.wi-night-hail:before {
  content: $night-hail;
}
.wi-night-lightning:before {
  content: $night-lightning;
}
.wi-night-partly-cloudy:before {
  content: $night-partly-cloudy;
}
.wi-night-rain:before {
  content: $night-rain;
}
.wi-night-rain-mix:before {
  content: $night-rain-mix;
}
.wi-night-rain-wind:before {
  content: $night-rain-wind;
}
.wi-night-showers:before {
  content: $night-showers;
}
.wi-night-sleet:before {
  content: $night-sleet;
}
.wi-night-sleet-storm:before {
  content: $night-sleet-storm;
}
.wi-night-snow:before {
  content: $night-snow;
}
.wi-night-snow-thunderstorm:before {
  content: $night-snow-thunderstorm;
}
.wi-night-snow-wind:before {
  content: $night-snow-wind;
}
.wi-night-sprinkle:before {
  content: $night-sprinkle;
}
.wi-night-storm-showers:before {
  content: $night-storm-showers;
}
.wi-night-thunderstorm:before {
  content: $night-thunderstorm;
}
.wi-lunar-eclipse:before {
  content: $lunar-eclipse;
}
.wi-stars:before {
  content: $stars;
}
.wi-storm-showers:before {
  content: $storm-showers;
}
.wi-thunderstorm:before {
  content: $thunderstorm;
}
.wi-night-alt-cloudy-high:before {
  content: $night-alt-cloudy-high;
}
.wi-night-cloudy-high:before {
  content: $night-cloudy-high;
}
.wi-night-alt-partly-cloudy:before {
  content: $night-alt-partly-cloudy;
}
