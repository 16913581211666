@import 'scss/variables';
@import 'scss/rules';
// @import '../../../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../../../node_modules/dragula/dist/dragula.css';
@import 'scss/mixins';
@import 'scss/core/palette';
@import 'scss/app-variables';
// @import 'scss/custom-bootstrap/';
@import 'scss/core';
@import 'scss/fonts/weather-icons';
@import 'scss/plugins';
@import 'scss/demo'; // Remove this SCSS from production
